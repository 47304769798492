import React from "react";
import { Link } from "gatsby";
import InstagramLogo from "../images/instagram.png";
import RightArrow from "../images/right-arrow.png";
import * as styles from "../styles/sass/layout/_aboutContact.module.scss";

const aboutContact = () => {
  return (
    <div className={styles.aboutContact}>
      <div className={styles.aboutContact__text}>
        <p>InstagramのDMにてお問合せ下さい。</p>
        <p>DM対応時間　平日１０時～１７時（不定休）</p>
      </div>
      <Link to="https://www.instagram.com/gogoisland__y/">
        <div className={styles.aboutContact__ctr}>
          <div className={styles.aboutContact__ctrInner}>
            <h3>
              <img src={InstagramLogo} alt="Instagramロゴ" />
              Instagramからのお問い合わせ
            </h3>
            <p>DM対応時間　平日１０時～１７時（不定休）</p>
            <img src={RightArrow} alt="右矢印" />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default aboutContact;
