import * as React from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import PageHeader from "../components/pageHeader";
import AboutContact from "../components/aboutContact";
import AboutInstagram from "../components/aboutInstagram";

const Contact = () => {
  return (
    <React.Fragment>
      <Header />
      <main>
        <PageHeader page="contact">CONTACT</PageHeader>
        <AboutContact />
        <AboutInstagram />
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Contact;

export const Head = () => <title>GoGo Island | CONTACT</title>
